import { useMemo, useState } from 'react';

type BooleanType = {
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
  set: (value: boolean) => void;
};

export const useBoolean = (defaultValue = false): [boolean, BooleanType] => {
  const [state, setState] = useState(defaultValue);

  const actions: BooleanType = useMemo(() => {
    const setTrue = () => setState(true);
    const setFalse = () => setState(false);
    const toggle = () => setState((s) => !s);
    const set = (v: boolean) => setState(v);

    return {
      toggle,
      setTrue,
      setFalse,
      set,
    };
  }, []);

  return [state, actions];
};
