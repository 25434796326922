import React from 'react';
import { Link } from 'react-router-dom';
import { useModal } from '@/hooks/useModal';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar } from '@mui/material';
import { Box, Button, Modal } from '@mui/material';

export const Header = React.memo(() => {
  const { user, logout } = useAuth0();
  const [open, actions] = useModal();

  const handleLogout = React.useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const domain = import.meta.env.VITE_AUTH0_DOMAIN as string;
  const STAGINGURI = 'stg-giho-willbox.jp.auth0.com' as string;
  const STAGING = 'Staging' as string;
  const displayStaging = domain === STAGINGURI ? STAGING : '';

  return (
    <div className="bg-notice-dark-default z-header sticky top-0 flex h-14 w-full min-w-full flex-row items-center justify-between p-2 pr-6 shadow-lg">
      {/* left */}
      <Link to="/">
        <div className="flex items-center gap-3.5">
          <img src="/images/logo/logo.svg" width="40" height="40" alt="logo" />
          <div className="text-lg font-bold">Admin container-ec</div>
          <p className="text-lg font-bold">{displayStaging}</p>
        </div>
      </Link>

      {/* right */}
      <div className="flex cursor-pointer items-center gap-3.5" onClick={actions.open}>
        <div className="text-base text-[#333333]">{user?.email}</div>
        <Avatar sx={{ width: 32, height: 32 }}>{user?.email?.charAt(0)}</Avatar>
      </div>

      {/* ログアウトモーダル */}
      <Modal open={open} onClose={actions.close} sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}>
        <Box
          className="flex flex-col bg-[#FFFFFF] drop-shadow-2xl"
          style={{ position: 'absolute', top: '4rem', right: '2.2rem' }}
        >
          <div className="flex justify-end">
            <Button onClick={handleLogout} className="hover:bg-shade-white-hover text-center text-[#000000]">
              ログアウト
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
});
