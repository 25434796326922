import { useMemo } from 'react';
import { useBoolean } from './useBoolean';

export type UseModal = [boolean, ModalActionType];

type ModalActionType = {
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export const useModal = (defaultValue = false): UseModal => {
  const [state, { toggle, set }] = useBoolean(defaultValue);

  const actions: ModalActionType = useMemo(() => {
    const open = () => set(true);
    const close = () => set(false);
    return {
      toggle,
      open,
      close,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, actions];
};
