import React, { PropsWithChildren } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { Layout } from '@/components/layout';
import { Auth0UrqlProvider } from '@/components/urql';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

const PrivateRouter = React.lazy(() => import('./private'));
const LoginPage = React.lazy(() => import('@/pages/login'));
const UserListPage = React.lazy(() => import('@/pages/user'));
const UserEditPage = React.lazy(() => import('@/pages/user/[id]/edit'));
const JoinCompanyListPage = React.lazy(() => import('@/pages/join-company'));
const JoinCompanyEditPage = React.lazy(() => import('@/pages/join-company/[id]/edit'));
const CompanyListPage = React.lazy(() => import('@/pages/company'));
const CompanyNewPage = React.lazy(() => import('@/pages/company/new'));
const CompanyEditPage = React.lazy(() => import('@/pages/company/[id]/edit'));
const SupplierListPage = React.lazy(() => import('@/pages/supplier'));
const SupplierNewPage = React.lazy(() => import('@/pages/supplier/new'));
const SupplierEditPage = React.lazy(() => import('@/pages/supplier/[supplierId]/edit'));
const ProjectListPage = React.lazy(() => import('@/pages/project'));
const ContainerECListPage = React.lazy(() => import('@/pages/containerec'));
const NewContainerECPage = React.lazy(() => import('@/pages/containerec/new'));
const ChangeDatasourceContainerECPage = React.lazy(() => import('@/pages/containerec/reset-datasource'));
const GroupOrderPage = React.lazy(() => import('@/pages/grouporder'));
const GroupOrderNewPage = React.lazy(() => import('@/pages/grouporder/new'));
const GroupOrderEditPage = React.lazy(() => import('@/pages/grouporder/[id]/edit'));
const RateInquiriesPage = React.lazy(() => import('@/pages/rateinquiries'));

const NotFound = React.lazy(() => import('@/pages/notfound'));
const MaintenancePage = React.lazy(() => import('@/pages/maintenance'));

const domain = import.meta.env.VITE_AUTH0_DOMAIN as string;
const client = import.meta.env.VITE_AUTH0_CLIENT_ID as string;
const audience = import.meta.env.VITE_AUTH0_AUDIENCE as string;

const AuthProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    // If using a Hash Router, you need to use window.history.replaceState to
    // remove the `code` and `state` query parameters from the callback url.
    // window.history.replaceState({}, document.title, window.location.pathname);
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={client}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      // cacheLocation="memory"
    >
      {children}
    </Auth0Provider>
  );
};

export const Router = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Auth0UrqlProvider>
          <Routes>
            <Route element={<PrivateRouter />}>
              <Route element={<Layout />}>
                <Route path="/" element={<ProjectListPage />} />
                <Route path="/containerec" element={<ContainerECListPage />} />
                <Route path="/containerec/new" element={<NewContainerECPage />} />
                <Route path="/containerec/reset-datasource" element={<ChangeDatasourceContainerECPage />} />
                <Route path="/users" element={<UserListPage />} />
                <Route path="/users/:id/edit" element={<UserEditPage />} />
                <Route path="/join-companies" element={<JoinCompanyListPage />} />
                <Route path="/join-companies/:id/edit" element={<JoinCompanyEditPage />} />
                <Route path="/companies" element={<CompanyListPage />} />
                <Route path="/companies/new" element={<CompanyNewPage />} />
                <Route path="/companies/:id/edit" element={<CompanyEditPage />} />
                <Route path="/suppliers" element={<SupplierListPage />} />
                <Route path="/suppliers/new" element={<SupplierNewPage />} />
                <Route path="/suppliers/:id/edit" element={<SupplierEditPage />} />
                <Route path="/projects" element={<ProjectListPage />} />
                <Route path="/grouporder" element={<GroupOrderPage />} />
                <Route path="/grouporder/new" element={<GroupOrderNewPage />} />
                <Route path="/grouporder/:id/edit" element={<GroupOrderEditPage />} />
                <Route path="/rateinquiries" element={<RateInquiriesPage />} />
              </Route>
            </Route>

            <Route path="maintenance" element={<MaintenancePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Auth0UrqlProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
