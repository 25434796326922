import { MdOutlineSentimentVeryDissatisfied, MdOutlineShield } from 'react-icons/md';
import { Button } from '@mui/material';

export const InternalServerError = () => {
  return (
    <div className="grid min-h-full w-full grid-cols-[min-content] place-content-center gap-6 p-6 antialiased">
      <span className="grid grid-cols-1 grid-rows-1 place-items-center">
        <MdOutlineShield size={64} className="icon-shade-medium-default col-[1] row-[1]" />

        <MdOutlineSentimentVeryDissatisfied
          size={56}
          className="icon-shade-medium-default col-[1] row-[1] [clip-path:circle(32%)]"
        />
      </span>

      <p className="whitespace-nowrap text-lg">予期しないエラーが発生しました</p>

      <p className="text-xl">
        正常にリクエストを処理することができませんでした。ご迷惑をおかけして申し訳ございません。
        <br />
        サーバーの混雑の可能性もありますので、お手数ですが数分お待ちいただいてから再度アクセスしていただけますと幸いです。
      </p>

      <p className="text-xs">
        何度もこのページが表示される場合、お手数ですが
        <a
          href="https://3-shake.com/contact/"
          target="_blank"
          rel="noreferrer"
          className="text-secondary-dark-default underline"
        >
          こちら
        </a>
        からその旨をご連絡いただけますと幸いです。
      </p>

      <div className="flex w-full place-content-center">
        <Button
          variant="contained"
          className="bg-primary-dark-default hover:bg-primary-dark-hover focus:bg-primary-dark-default active:bg-primary-dark-default text-[#FFFFFF]"
        >
          元のページに戻る
        </Button>
      </div>
    </div>
  );
};
