import React from 'react';
import clsx from 'clsx';
import { match } from 'path-to-regexp';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight, HiOutlineShoppingCart } from 'react-icons/hi2';
import {
  MdAccountCircle,
  MdAddReaction,
  MdConfirmationNumber,
  MdHome,
  MdMessage,
  MdOutlineAccountCircle,
  MdOutlineCorporateFare,
  MdOutlineDirectionsBoatFilled,
  MdOutlineSettingsPower,
  MdSdStorage,
} from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar as UISidebar } from '@/components/ui-parts/layout/sidebar';
import { SidebarContext } from '@/components/ui-parts/layout/sidebar/SidebarContext';
import { SidebarMenu, SidebarMenuItem, SidebarMenuTitle } from '@/components/ui-parts/layout/sidebar/SidebarMenu';

export const Sidebar = React.memo(() => {
  const location = useLocation();

  return (
    <UISidebar defaultOpen={false}>
      <SidebarContext.Consumer>
        {({ open, handleOpen, handleClose }) => (
          <>
            <SidebarMenu>
              {open ? (
                <div onClick={handleClose}>
                  <div className="text-shade-dark-default text-r flex cursor-pointer items-center justify-end p-4 font-medium">
                    <HiOutlineChevronDoubleLeft size={24} className={clsx(['icon-shade-dark-default'])} />
                  </div>
                </div>
              ) : (
                <div onClick={handleOpen}>
                  <div className="text-shade-dark-default text-r flex cursor-pointer items-center  p-4 font-medium">
                    <HiOutlineChevronDoubleRight size={24} className={clsx(['icon-shade-dark-default'])} />
                  </div>
                </div>
              )}
              <SidebarMenuTitle IconComponent={MdOutlineSettingsPower} title="基本機能" />
              <Link to="/">
                <SidebarMenuItem
                  IconComponent={MdHome}
                  label="案件"
                  active={
                    !!match('/', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>
              <Link to="/containerec">
                <SidebarMenuItem
                  IconComponent={HiOutlineShoppingCart}
                  label="コンテナEC"
                  active={
                    !!match('/containerec', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>
              <Link to="/grouporder">
                <SidebarMenuItem
                  IconComponent={MdConfirmationNumber}
                  label="共同購買"
                  active={
                    !!match('/grouporder', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>
              <Link to="/rateinquiries">
                <SidebarMenuItem
                  IconComponent={MdMessage}
                  label="お問い合わせ"
                  active={
                    !!match('/rateinquiries', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>
              <SidebarMenuTitle IconComponent={MdAccountCircle} title="アカウント管理" />
              <Link to="/companies">
                <SidebarMenuItem
                  IconComponent={MdOutlineCorporateFare}
                  label="会社"
                  active={
                    !!match('/companies(.*)', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>
              <Link to="/users">
                <SidebarMenuItem
                  IconComponent={MdOutlineAccountCircle}
                  label="ユーザー"
                  active={
                    !!match('/users(.*)', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>
              <Link to="/join-companies">
                <SidebarMenuItem
                  IconComponent={MdAddReaction}
                  label="会社登録リクエスト"
                  active={
                    !!match('/join-companies(.*)', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>
              <SidebarMenuTitle IconComponent={MdSdStorage} title="マスタ管理" />
              <Link to="/suppliers">
                <SidebarMenuItem
                  IconComponent={MdOutlineDirectionsBoatFilled}
                  label="業者"
                  active={
                    !!match('/suppliers(.*)', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>
            </SidebarMenu>
          </>
        )}
      </SidebarContext.Consumer>
    </UISidebar>
  );
});
