import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { App } from './app';
import './styles/index.css';
import './styles/tailwindcss.v2.css';

// eslint-disable-next-line no-console
console.log('rev', import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA);
Sentry.init({
  dsn:
    import.meta.env.VITE_IN_LOCAL === 'true'
      ? ''
      : 'https://73244ff456e66152ebaf64cf48112c90@o4504478126571520.ingest.sentry.io/4506268026077184',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/api\.container-ec\.\.jp\/graphql/],
    }),
    new Sentry.Replay(),
  ],
  environment: import.meta.env.VITE_SENTRY_ENV,
  release: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
